<template>
  <div class="card">
    <div class="bg-blue-light px-1 rounded pb-2">
      <TitleButton
          class="mt-1"
          btnTitle="List"
          :showBtn="true"
          :showAddNew="false"
          :title="headerTitle"
          @onClickCloseButton="navigateToListPage"
      />
    </div>
    <div class="px-2 mt-1">
      <div class="card card-height">
        <div class="row mt-2 gy-1">
          <div class="row mt-1">
            <div class="col-12 col-sm-6 col-md-4 col-lg-4 mb-1">
              <label class="form-label">Title</label>
              <input
                  class="form-control invoice-edit-input  "
                  tabindex="1"
                  type="text"
                  placeholder="Title...."
                  v-model="formData.title"
              >
            </div>

            <div class="col-12 col-sm-6 col-md-3">
              <div class="mb-2 row">
                <label class="align-middle">Select Pages</label>
                <v-select
                    placeholder="Select Create Pages"
                    v-model="formData.page_id"
                    :options="allPages"
                    label="label"
                    :reduce="label => label.id"
                />
              </div>
            </div>

            <div class="col-12 col-sm-6 col-md-3 col-lg-3  mb-1">
              <label class="align-middle">Message Type</label>
              <v-select
                  placeholder="Select Message Type"
                  v-model="formData.message_type"
                  :options="messageType"
                  :reduce="name => name.value"
                  label="name"
              />
            </div>

            <div v-if="formData.message_type === 'mail'"  class="col-12 col-sm-6 col-md-2 col-lg-2  mb-1">
              <label class="align-middle me-2">Is Attachment: </label>
              <input type="checkbox" v-model="formData.is_attachment" class="form-check-input">
            </div>

            <div v-if="!isCreate" class="col-12 col-sm-6 col-md-4 col-lg-4  mb-1">
              <label class="align-middle">Status</label>
              <v-select
                  placeholder="Select Status"
                  v-model="formData.status"
                  :options="status"
                  label="name"
                  :reduce="name => name.value"
              />
            </div>
            <hr>
            <div class="col-12">
              <div v-for="(generals, index) in template_generals" :key="index" class="row">
                <div class="col-md-4 col-12">
                  <div class="mb-1">
                    <input v-model="generals.text" type="text" class="form-control" id="text"
                           placeholder="Message Text.."/>
                  </div>
                </div>
                <div class="col-md-3 col-12">
                  <div class="mb-1">
                    <v-select
                        placeholder="Select Value"
                        v-model="generals.value"
                        :options="messageValue"
                        label="name"
                        :reduce="name => name.value"
                    />
                  </div>
                </div>
                <div class="col-md-1 col-12">
                  <div class="mb-1">
                    <input type="checkbox" v-model="generals.is_newline" class="form-check-input"> Is Newline
                  </div>
                </div>
                <div class="col-md-2 col-12 p_left_0">
                  <div class="mb-1 _skill_out_visibility">
                    <button v-if="template_generals.length === (index + 1)" @click="addMore"
                            class="btn btn-outline-primary btn-sm text-nowrap px-1 button_icon" type="button">
                      <i class="fa fa-plus"></i>
                    </button>
                    <button v-else @click="remove(index)"
                            class="btn btn-outline-danger btn-sm text-nowrap px-1 button_icon" type="button">
                      <i class="fa fa-trash"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-2 d-flex justify-content-start justify-content-sm-center px-2 gap-1 flex-wrap pb-2">
      <hr class="hr-full-width mb-1">
      <button :disabled="saveButtonLoader || saveNewButtonLoader" @click="saveAP()"
              class="btn btn-primary">
        Save
      </button>
      <button :disabled="saveButtonLoader || saveNewButtonLoader" @click="saveAP(false)"
              class="btn btn-primary">Save & New
      </button>
      <button @click="navigateToListPage" class="btn btn-outline-secondary">Cancel</button>
    </div>

    <GlobalLoader/>
  </div>
</template>
<script setup>
import {computed, inject, onMounted, ref} from 'vue'
import {useRoute, useRouter} from "vue-router";
import TitleButton from '@/components/atom/TitleButton'
import GlobalLoader from "@/components/atom/GlobalLoader.vue";
import {useStore} from "vuex";
import handleWorkflow from "@/services/modules/approvalMatrix/workflowMatrix";
import handleInventory from "@/services/modules/inventory";

const store = useStore();
const router = useRouter()
const route = useRoute()
const showError = inject('showError');
const showSuccess = inject('showSuccess')
const companyId = computed(() => {
  return route.params.companyId
})

let loader =ref(false)
let saveButtonLoader = ref(false)
let saveNewButtonLoader = ref(false)
let isCreate = ref(true)
let headerTitle = ref("Add Message Template")
let contacts = ref([])
let locations = ref([])
const allPages = ref([]);
let accountPayable = ref([])
let messageTemplateEditData = ref({})
const status = ref([]);
const messageType = ref([]);
const messageValue = ref([])
let messageTemplateData = {}
let formData = ref({
  company_id: companyId,
  status: 'active',
  title:'',
  page_id:'',
  message_type:'',
  is_attachment:false,
})

const template_generals = ref([
  {
    text: '',
    value: '',
    is_newline: false,
  }
])
const addMore = () => {
  template_generals.value.push({
    text: '',
    value: '',
    is_newline: false,
  })
}
const remove = index => {
  template_generals.value.splice(index, 1)
}

function navigateToListPage() {
  delete route.query.messageTemplateId;
  router.push({name: `message-template`, params: route.params, query: route.query});
}

const { fetchHome } = handleInventory()
const { fetchPages, storeMessageTemplate, fetchSingleMessageTemplate, updateMessageTemplate } = handleWorkflow()
function saveAP(redirect = true) {
  if (isCreate.value) {
    handleSubmit(redirect);
  } else {
    handleUpdate(redirect);
  }
}

function handleSubmit(redirect) {
  saveButtonLoader.value = true
  messageTemplateData.company_id  = formData.value.company_id;
  messageTemplateData.status = "active";
  messageTemplateData.title = formData.value.title;
  messageTemplateData.page_id = formData.value.page_id;
  messageTemplateData.message_type = formData.value.message_type;
  messageTemplateData.is_attachment = formData.value.is_attachment;
  messageTemplateData.item_details = template_generals.value

  createMT(redirect);
}

function handleUpdate(redirect) {
  saveButtonLoader.value = true
  messageTemplateData.id = formData.value.id;
  messageTemplateData.company_id  = formData.value.company_id;
  messageTemplateData.status = formData.value.status;
  messageTemplateData.title = formData.value.title;
  messageTemplateData.page_id = formData.value.page_id;
  messageTemplateData.message_type = formData.value.message_type;
  messageTemplateData.is_attachment = formData.value.is_attachment;
  messageTemplateData.item_details = template_generals.value
  updateMTData(redirect);
}

function createMT(redirect = true) {
  storeMessageTemplate(messageTemplateData, getQuery())
      .then(res => {
        if (!res.status) {
          showError(res.message)
          return
        }
         showSuccess(res.message)
         if (redirect) navigateToListPage()
      })
      .catch(err => {
        console.log(err)
      }).finally(() => {
        loader.value = false
        saveButtonLoader.value = false
      })
}

function updateMTData(redirect = true) {
  updateMessageTemplate(messageTemplateData, messageTemplateData.id, getQuery())
      .then(res => {
        if (!res.status) {
          showError(res.message)
          return
        }
         showSuccess(res.message);
         if (redirect) navigateToListPage()
      })
      .catch(err => {
        console.log(err)
      }).finally(() => {
        loader.value = false
        saveButtonLoader.value = false
      })
}

function getQuery() {
  return `?company_id=${route.params.companyId}`;
}

function setFormData() {
  headerTitle.value = "Update Message Template ";
  formData.value.id =  messageTemplateEditData.value.id;
  formData.value.title = messageTemplateEditData.value.title;
  formData.value.page_id = messageTemplateEditData.value.page_id;
  formData.value.message_type = messageTemplateEditData.value.message_type;
  formData.value.is_attachment = messageTemplateEditData.value.is_attachment === 1;
  formData.value.status = messageTemplateEditData.value.status;
  if (messageTemplateEditData.value.message_template_generals.length > 0){
    template_generals.value = messageTemplateEditData.value.message_template_generals.map(item => {
      return {
        ...item,
        text: item.text || '',
        value: item.value || '',
        is_newline: item.is_newline === 1,
      };
    })
  }
}

const getTypes = async () => {
  let query = getQuery();
  await fetchHome(query).then(res => {
    if (!res.status){
      status.value = [];
      messageType.value = [];
      messageValue.value = [];
      return
    }
    if (res.data && res.data.status) {
      status.value = Object.keys(res.data.status)
          .filter(key => ["active", "inactive"].includes(key))
          .map(key => ({
            value: key,
            name: res.data.status[key]
          }));
    }
    if (res.data && res.data.message_type) {
      messageType.value = Object.keys(res.data.message_type)
          .map(key => ({
            value: key,
            name: res.data.message_type[key]
          }));
    }
    if (res.data && res.data.message_value) {
      messageValue.value = Object.keys(res.data.message_value)
          .map(key => ({
            value: key,
            name: res.data.message_value[key]
          }));
    }

  }).catch(err => {
    console.log(err)
  })
}

function onPageReload() {
  loader.value=true
  let query = getQuery();
  let allPagesQuery = query + `&get_all=1&is_message=1`

  Promise.all([
    getTypes(),

    fetchPages(allPagesQuery).then(res => {
      if (res.data){
        allPages.value = res.data;
      }
    }),

  ]).finally(() => {
    loader.value = false
  })

  if(route.query.messageTemplateId && route.query.messageTemplateId !== '' && route.query.messageTemplateId !== undefined){
    isCreate.value = false;
    const fetchSingleData = fetchSingleMessageTemplate(route.query.messageTemplateId, getQuery());
    fetchSingleData.then(res2 => {
      messageTemplateEditData.value = res2.data;
      setFormData();
    });
  }

}

onMounted(() => {
  onPageReload();
})
</script>